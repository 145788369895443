<template>
    <section class="settings-comissions">
        <spinner class="settings-comissions__spinner" v-if="status === 'loading'" />
        <error-message class="settings-comissions__error-message" v-else-if="status === 'error'">
            Не удалось загрузить настройки.
        </error-message>
        <template v-else>
            <header class="settings-comissions__header">
                <h4 class="settings-comissions__title h3">Список комиссий</h4>
                <button class="settings-comissions__action settings-comissions__action_add"
                    @click="showComissionFormModal(null)"
                >
                    Добавить
                </button>
            </header>
            <div class="settings-comissions__box box">
                <table class="settings-comissions__table settings-comissions__table_striped">
                    <colgroup>
                        <col class="settings-comissions__table-column settings-comissions__table-column_id">
                        <col class="settings-comissions__table-column settings-comissions__table-column_origin">
                        <col class="settings-comissions__table-column settings-comissions__table-column_type">
                        <col class="settings-comissions__table-column settings-comissions__table-column_order_type">
                        <col class="settings-comissions__table-column settings-comissions__table-column_value">
                        <col class="settings-comissions__table-column settings-comissions__table-column_reports-settings">
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="settings-comissions__table-cell settings-comissions__table-cell_head settings-comissions__table-cell_sortable"
                                scope="col"
                                @click="setSorting('id')"
                            >
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">Id</span>
                                    <svg-icon class="settings-comissions__table-value settings-comissions__table-value_icon"
                                        v-if="sorting.field === 'id'"
                                        :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="settings-comissions__table-cell settings-comissions__table-cell_head settings-comissions__table-cell_sortable"
                                scope="col"
                                @click="setSorting('origin')"
                            >
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">Источник</span>
                                    <svg-icon class="settings-comissions__table-value settings-comissions__table-value_icon"
                                        v-if="sorting.field === 'origin'"
                                        :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="settings-comissions__table-cell settings-comissions__table-cell_head settings-comissions__table-cell_sortable"
                                scope="col"
                                @click="setSorting('type')"
                            >
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">Тип обслуживания</span>
                                    <svg-icon class="settings-comissions__table-value settings-comissions__table-value_icon"
                                        v-if="sorting.field === 'type'"
                                        :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="settings-comissions__table-cell settings-comissions__table-cell_head settings-comissions__table-cell_sortable"
                                scope="col"
                                @click="setSorting('order_type')"
                            >
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">Тип заказа</span>
                                    <svg-icon class="settings-comissions__table-value settings-comissions__table-value_icon"
                                        v-if="sorting.field === 'order_type'"
                                        :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="settings-comissions__table-cell settings-comissions__table-cell_head settings-comissions__table-cell_sortable"
                                scope="col"
                                @click="setSorting('value')"
                            >
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_right">
                                    <span class="settings-comissions__table-value">Комиссия</span>
                                    <svg-icon class="settings-comissions__table-value settings-comissions__table-value_icon"
                                        v-if="sorting.field === 'value'"
                                        :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="settings-comissions__table-cell settings-comissions__table-cell_head" scope="col">
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">Действия</span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="settings-comissions__table-row settings-comissions__table-row_body"
                            v-for="comission in comissionsView"
                            :key="comission.id"
                        >
                            <td class="settings-comissions__table-cell settings-comissions__table-cell_body">
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">{{comission.id}}</span>
                                </span>
                            </td>
                            <td class="settings-comissions__table-cell settings-comissions__table-cell_body">
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">{{comission.origin}}</span>
                                </span>
                            </td>
                            <td class="settings-comissions__table-cell settings-comissions__table-cell_body">
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">{{comission.type}}</span>
                                </span>
                            </td>
                            <td class="settings-comissions__table-cell settings-comissions__table-cell_body">
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <span class="settings-comissions__table-value">{{comission.order_type}}</span>
                                </span>
                            </td>
                            <td class="settings-comissions__table-cell settings-comissions__table-cell_body">
                                <span class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_right">
                                    <span class="settings-comissions__table-value">{{comission.value | percentIntFormat}}</span>
                                </span>
                            </td>
                            <td class="settings-comissions__table-cell settings-comissions__table-cell_body">
                                <div class="settings-comissions__cell-content settings-comissions__cell-content_columns settings-comissions__cell-content_left">
                                    <button class="settings-comissions__row-action settings-comissions__row-action_edit" @click="showComissionFormModal(comission.id)">
                                        <span class="settings-comissions__table-value">Изменить</span>
                                    </button>
                                    <button class="settings-comissions__row-action settings-comissions__row-action_delete" @click="showDeleteComissionModal(comission.id)">
                                        <span class="settings-comissions__table-value">Удалить</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <modal class="settings-comissions__modal"
                classes="settings-comissions__modal-content settings-comissions__modal-content_delete"
                :name="'deleteComissionModal'"
                :min-width="300"
                :max-width="470"
                height="200px"
                :adaptive="true"
            >
                <template v-if="comissionToDelete">
                    <p class="settings-comissions__modal-heading h2">Удалить комиссию<br> "{{comissionToDelete.origin}}, {{comissionToDelete.type}}, {{comissionToDelete.value | percentIntFormat}}"?</p>
                    <p class="settings-comissions__modal-text">После выполнения это действие не может быть отменено.</p>
                    <div class="settings-comissions__actions settings-comissions__actions_modal">
                        <button class="settings-comissions__action settings-comissions__action_cancel"
                            @click="closeDeleteComissionModal"
                        >
                            Отмена
                        </button>
                        <button class="settings-comissions__action settings-comissions__action_delete"
                            @click="deleteComission"
                        >
                            Удалить
                        </button>
                    </div>
                </template>
            </modal>
            <modal class="settings-comissions__modal"
                classes="settings-comissions__modal-content settings-comissions__modal-content_form"
                :name="'comissionFormModal'"
                :min-width="300"
                :max-width="600"
                height="auto"
                :adaptive="true"
            >
                <form class="settings-comissions__user-form" @submit="saveComission">
                    <p class="settings-comissions__modal-heading h1">
                        {{`${comissionToEditId ? 'Редактирование' : 'Добавление'} комиссии`}}
                    </p>
                    <div class="settings-comissions__controls">
                        <div class="settings-comissions__control settings-comissions__control_full-width">
                            <label class="settings-comissions__control-label h3" for="origin">Источник</label>
                            <input class="settings-comissions__control-input"
                                :class="{ 'settings-comissions__control-input_invalid': comissionFormData.origin.isInvalid }"
                                type="text"
                                id="origin"
                                :value="comissionFormData.origin.value"
                                @input="handleComissionFormInput('origin', $event)"
                            >
                            <span class="settings-comissions__control-error" v-if="comissionFormData.origin.error">{{comissionFormData.origin.error}}</span>
                        </div>
                        <div class="settings-comissions__control settings-comissions__control_full-width">
                            <label class="settings-comissions__control-label h3" for="type">Тип обслуживания</label>
                            <select class="settings-comissions__select"
                                id="type"
                                v-model="comissionFormData.type"
                            >
                                <option class="settings-comissions__option"
                                    v-for="serviceType in serviceTypes"
                                    :key="serviceType"
                                    :value="serviceType"
                                >
                                    {{serviceType}}
                                </option>
                            </select>
                        </div>
                        <div class="settings-comissions__control settings-comissions__control_full-width">
                            <label class="settings-comissions__control-label h3" for="order_type">Тип заказа</label>
                            <input class="settings-comissions__control-input"
                                type="text"
                                id="order_type"
                                :value="comissionFormData.order_type"
                                @input="handleComissionFormInput('order_type', $event)"
                            >
                        </div>
                        <div class="settings-comissions__control settings-comissions__control_full-width">
                            <label class="settings-comissions__control-label h3" for="value">Комиссия (в процентах)</label>
                            <input class="settings-comissions__control-input"
                                :class="{ 'settings-comissions__control-input_invalid': comissionFormData.value.isInvalid }"
                                type="text"
                                id="value"
                                :value="comissionFormData.value.value"
                                @input="handleComissionFormInput('value', $event)"
                            >
                            <span class="settings-comissions__control-error" v-if="comissionFormData.value.error">{{comissionFormData.value.error}}</span>
                        </div>
                    </div>
                    <div class="settings-comissions__actions settings-comissions__actions_modal">
                        <button class="settings-comissions__action settings-comissions__action_cancel"
                            type="reset"
                            @click="closeComissionFormModal"
                        >
                            Отмена
                        </button>
                        <button class="settings-comissions__action settings-comissions__action_delete"
                            type="submit"
                            :disabled="isComissionFormSubmitDisabled"
                        >
                            Сохранить
                        </button>
                    </div>
                </form>
            </modal>
        </template>
    </section>
</template>

<script>
    import { mapState, mapGetters, mapActions } from "vuex";
    import { compareStrings, compareNumbers } from "@/helpers/compare";

    export default {
        name: "SettingsComissions",
        data() {
            return {
                status: "loading",
                sorting: {
                    field: "value",
                    direction: "DESC"
                },
                comissionToDeleteId: null,
                comissionToEditId: null,
                comissionFormData: {
                    origin: {
                        value: null,
                        isInvalid: false,
                        error: null
                    },
                    type: "delivery",
                    order_type: null,
                    value: {
                        value: null,
                        isInvalid: false,
                        error: null
                    },
                },
            };
        },
        computed: {
            ...mapState({
                comissions: state => state.settings.comissions,
            }),
            ...mapGetters([
                "areComissionsLoaded",
            ]),
            comissionsView() {
                return this.comissions?.sort((commission1, commission2) => {
                    let compare;

                    switch (this.sorting.field) {
                        case "id":
                        case "value":
                            compare = compareNumbers;
                            break;
                        default:
                            compare = compareStrings;
                            break;
                    };

                    return compare(
                        commission1[this.sorting.field],
                        commission2[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
            comissionToDelete() {
                return this.comissions?.find(({ id }) => id === this.comissionToDeleteId);
            },
            comissionToEdit() {
                return this.comissions?.find(({ id }) => id === this.comissionToEditId);
            },
            serviceTypes() {
                return ["Доставка", "Зал", "С собой", "Самовынос"];
            },
            isComissionFormSubmitDisabled() {
                if (this.comissionToEdit) {
                    return this.comissionFormData.origin.isInvalid || this.comissionFormData.value.isInvalid;
                } else {
                    return !this.comissionFormData.origin.value ||
                        this.comissionFormData.origin.isInvalid ||
                        this.comissionFormData.value.isInvalid;
                }
            }
        },
        methods: {
            ...mapActions([
                "getComissionsFromDB",
                "createMessage",
                "deleteComissionFromDB",
                "updateComissionInDB",
                "addComissionToDB",
            ]),
            showData() {
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadUsers() {
                this.getComissionsFromDB().then(this.showData, this.showError);
            },
            setSorting(field) {
                if (this.sorting.field === field) {
                    this.sorting.direction = this.sorting.direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sorting.field = field
                }
            },
            showDeleteComissionModal(id) {
                this.comissionToDeleteId = id;
                this.$modal.show("deleteComissionModal");
            },
            closeDeleteComissionModal() {
                this.$modal.hide("deleteComissionModal");
                this.comissionToDeleteId = null;
            },
            deleteComission() {
                this.$modal.hide("deleteComissionModal");

                this.deleteComissionFromDB(this.comissionToDeleteId).then(() => {
                    this.createMessage({
                        type: "success",
                        text: "Комиссия удалена"
                    });
                }).catch(() => {
                    this.createMessage({
                        type: "failure",
                        text: "Не удалось удалить комиссию"
                    });
                }).finally(() => {
                    this.comissionToDeleteId = null;
                });
            },
            showComissionFormModal(id) {
                if (id) {
                    this.comissionToEditId = id;

                    this.comissionFormData.type = this.comissionToEdit.type;
                    this.comissionFormData.order_type = this.comissionToEdit.order_type;

                    this.comissionFormData.origin.value = this.comissionToEdit.origin;

                    this.comissionFormData.value.value = Math.round(this.comissionToEdit.value * 100);
                } else {
                    this.comissionToEditId = null;

                    this.comissionFormData.type = "Доставка";
                    this.comissionFormData.order_type = null;

                    this.comissionFormData.origin.value = null;

                    this.comissionFormData.value.value = 0;
                }

                for (const parameter of ["origin", "value"]) {
                    this.comissionFormData[parameter].isInvalid = false;
                    this.comissionFormData[parameter].error = null;
                }

                this.$modal.show("comissionFormModal");
            },
            closeComissionFormModal() {
                this.$modal.hide("comissionFormModal");
            },
            handleComissionFormInput(parameter, { target: { value }}) {
                console.log(value);

                if (parameter === "origin") {
                    this.comissionFormData.origin.value = value;

                    if (value === "") {
                        this.comissionFormData.origin.isInvalid = true;
                        this.comissionFormData.origin.error = "Источник должен быть заполнен";
                    } else {
                        this.comissionFormData.origin.isInvalid = false;
                        this.comissionFormData.origin.error = null;
                    }
                } else if (parameter === "order_type") {
                    this.comissionFormData.order_type = value;
                } else if (parameter === "value") {
                    this.comissionFormData.value.value = value;

                    if (value === "") {
                        this.comissionFormData.value.isInvalid = true;
                        this.comissionFormData.value.error = "Комиссия должна быть заполнена";
                    } else if (window.isNaN(value)) {
                        this.comissionFormData.value.isInvalid = true;
                        this.comissionFormData.value.error = "Некорректное значение";
                    } else if (Number(value) > 100) {
                        this.comissionFormData.value.isInvalid = true;
                        this.comissionFormData.value.error = "Комиссия не может быть больше 100%";
                    } else {
                        this.comissionFormData.value.isInvalid = false;
                        this.comissionFormData.value.error = null;
                    }
                }
            },
            saveComission(event) {
                event.preventDefault();

                if (this.comissionToEdit) {
                    let updates = {
                        type: this.comissionFormData.type
                    };

                    if (this.comissionFormData.origin.value && !this.comissionFormData.origin.isInvalid) {
                        updates.origin = this.comissionFormData.origin.value;
                    }

                    if (this.comissionFormData.order_type !== null) {
                        updates.order_type = this.comissionFormData.order_type;
                    }

                    if (this.comissionFormData.value.value && !this.comissionFormData.value.isInvalid) {
                        updates.value = Number(this.comissionFormData.value.value) / 100;
                    }

                    this.updateComissionInDB({
                        id: this.comissionToEditId,
                        updates
                    }).then(() => {
                        this.createMessage({
                            type: "success",
                            text: `Изменения сохранены`
                        });
                    }).catch(() => {
                        this.createMessage({
                            type: "failure",
                            text: `Не удалось сохранить изменения`
                        });
                    }).finally(() => {
                        this.comissionToEditId = null;
                    });
                } else {
                    let comissioData = {
                        origin: this.comissionFormData.origin.value,
                        type: this.comissionFormData.type,
                        order_type: this.comissionFormData.order_type,
                        value: Number(this.comissionFormData.value.value) / 100,
                    };

                    this.addComissionToDB(comissioData).then(() => {
                        this.createMessage({
                            type: "success",
                            text: "Комиссия создана"
                        });
                    }).catch(() => {
                        this.createMessage({
                            type: "failure",
                            text: "Не удалось создать комиссию"
                        });
                    });
                }

                this.closeComissionFormModal();
            }
        },
        created() {
            if (this.areComissionsLoaded) {
                this.showData();
            } else {
                this.loadUsers();
            }
        }
    }
</script>

<style lang="scss">
    .settings-comissions__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
    }
    .settings-comissions__title {
        line-height: 1;
    }
    .settings-comissions__box {
        overflow-x: auto;
    }
    .settings-comissions__table {
        width: 100%;
    }
    .settings-comissions__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .settings-comissions__table-column {
    }
    .settings-comissions__table-row {
        border-bottom: 1px solid $gray-line;

        &_body {
            background-color: $background-gray-primary;
        }
    }
    .settings-comissions__table-cell {
        padding: 16px;
        font-size: 16px;

        &_head {
            font-size: 18px;
        }

        &_body {
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .settings-comissions__cell-content {
        display: grid;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.settings-comissions__cell-content_left {
                justify-content: start;
            }

            &.settings-comissions__cell-content_right {
                justify-content: end;
                text-align: right;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.settings-comissions__cell-content_left {
                justify-items: start;
            }

            &.settings-comissions__cell-content_right {
                justify-items: end;
            }
        }
    }
    .settings-comissions__table-value {
        display: block;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 18px;
            height: 18px;
        }
    }
    .settings-comissions__modal-content {
        padding: 20px;
        overflow: visible;

        &_delete {
            text-align: center;
        }
    }
    .settings-comissions__modal-heading {
        margin-bottom: 10px;
    }
    .settings-comissions__actions {
        margin-top: 20px;

        &_form {
            text-align: right;
        }
    }
    .settings-comissions__action {
        padding: 10px 15px;
        border: 1px solid;
        border-radius: 4px;

        & + & {
            margin-left: 20px;
        }

        &_cancel {
            border-color: $gray-line;
        }

        &_add {
            width: 180px;
            color: #fff;
            border-color: $green-primary;
            background-color: $green-primary;
        }

        &_delete {
            color: #fff;
            border-color: $red-primary;
            background-color: $red-primary;
        }

        &[disabled] {
            cursor: not-allowed;
            border-color: $gray-line;
            background-color: $gray-line;
        }
    }
    .settings-comissions__controls {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
        grid-gap: 4px 10px;
    }
    .settings-comissions__control {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 16px;
        position: relative;

        &_full-width {
            grid-column: 1/-1;
        }

        & .multiselect__tags-wrap {
            display: none !important;
        }
    }
    .settings-comissions__control-label {
        margin: 0 0 5px;
    }
    .settings-comissions__control-input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $gray-line;
        border-radius: 4px;

        font-size: 16px;
        line-height: 1;

        &[disabled] {
            background-color: $gray-line;
        }

        @include desktop {
            font-size: 20px;
        }

        &:focus {
            outline: none;
        }

        &_invalid {
            border-color: $red-secondary;
        }
    }
    .settings-comissions__control-error {
        color: $red-secondary;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .settings-comissions__control-select {
        width: 100%;
        height: 42px;
        padding: 5px;
        border: 1px solid $gray-line;
        border-radius: 4px;
        cursor: pointer;

        @include desktop {
            font-size: 20px;
        }

        &:focus {
            outline: none;
        }
    }
    .settings-comissions__row-action {
        border: 1px solid;
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 14px;
        text-align: center;

        & + & {
            margin-left: 10px;
        }

        &:hover {
            color: #fff;
        }

        &_edit {
            color: $blue-primary;

            &:hover {
                background-color: $blue-primary;
            }
        }

        &_delete {
            color: $red-primary;

            &:hover {
                background-color: $red-primary;
            }
        }
    }
    .settings-comissions__select {
        width: 100%;
        height: 40px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
        font-size: 20px;
    }
</style>
